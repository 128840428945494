.footer {
	background: #14171b;
	bottom: 0px;
	font-size: 12px;
	height: 90px;
	position: relative;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	padding-top: 10px;
    flex-grow: 0;
    flex-shrink: 0;
}

.footer .container {
	color: white;
	font-size: medium;
}

.footer .container .copyright {
	color: grey;
}

.navbar-brand {
	cursor: pointer;
}
