.row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.version-block {
	font-size: 10px;
	color: grey;
	margin-left: 12px;
}

/* Rotation */
.scene {
	width: 50px;
	height: 50px;
	perspective: 150px;
}

.cube {
	width: 100%;
	height: 100%;
	position: relative;
	transform-style: preserve-3d;
	transform: translateZ(-25px);
}

.cube__face {
	position: absolute;
	width: 50px;
	height: 50px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cube__face--front {
	background-color: rgb(192, 192, 192);
	transform: rotateY(0deg) translateZ(25px);

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.cube__face--right {
	background-color: rgb(105, 105, 105);
	transform: rotateY(90deg) translateZ(25px);
}
.cube__face--back {
	background-color: rgb(44, 44, 44);
	transform: rotateY(180deg) translateZ(25px);
}
.cube__face--left {
	background-color: rgb(158, 158, 158);
	transform: rotateY(-270deg) translateZ(-25px);
}
.cube__face--top {
	background-color: rgb(255, 255, 255);
	transform: rotateX(90deg) translateZ(25px);
}
.cube__face--bottom {
	background-color: rgb(255, 255, 255);
	transform: rotateX(-90deg) translateZ(25px);
}

.cube {
	transform: translateZ(-25px);

	font-size: 24px;
}

/* .cube.show-front  { transform: translateZ(-100px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-100px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-100px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-100px) rotateY(  90deg); } */

/* .cube.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-100px) rotateX(  90deg); } */

/* .cube {
	transition: transform 0.2s;
} */

.lens {
	aspect-ratio: 1/1;
	width: 30%;

	background-color: rgb(233, 233, 233);
	border: 2px solid rgb(39, 39, 39);

	border-radius: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pupil {
	aspect-ratio: 1/1;
	width: 40%;

	margin: 10% 0 0 20%;

	background-color: rgb(15, 98, 153);
	border-radius: 50%;
}