.datas {
    display: flex;
    flex-direction: column;
	flex-grow: 1 !important;

    padding: 12px 0px !important;
}

.main-firstline {
	width: 100%;

	margin: 0 0 12px 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: first baseline;
}

.main-firstline-title {

}

.main-firstline-sessionchoice {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: first baseline;
}

.main-firstline-sessionchoice-button {
}

.loaderIcon {
	aspect-ratio: 32px;
	width: 32px;
	height: 32px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}